
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController
} from '@ionic/vue';
import { defineComponent, ref, Ref } from "vue";
import { play, stop, speedometerOutline } from 'ionicons/icons';
import { getEnabledImagePathsAsync, ImageSetting } from '@/composables/imageSettings';
import { preloadSoundAsync } from '@/composables/audioControls';
import Header from '@/components/Header.vue';
import SlidingImage from '@/components/SlidingImage.vue';
import TrackControls from '@/components/TrackControls.vue';
import { TrackControlSettings } from '@/models/TrackControlSettings';

interface SignatureInterface {
  intervals: number[];
  volumes: number[];
}

const MAX_TRACKS = 6;
const INTERVAL_MS = 250;
const signatures: { [key: string]: SignatureInterface } = {
  '2/4': { intervals: [4, 4], volumes: [1, 0.3] },
  '3/4': { intervals: [4, 4, 4], volumes: [1, 0.3, 0.3] },
  '4/4': { intervals: [4, 4, 4, 4], volumes: [1, 0.3, 0.3, 0.3] },
  '5/4': { intervals: [4, 4, 4, 4, 4], volumes: [1, 0.3, 0.3, 0.3, 0.3] },
  '6/8': { intervals: [2, 2, 2, 2, 2, 2], volumes: [1, 0.3, 0.3, 1, 0.3, 0.3] },
  'SQQ': { intervals: [3, 2, 2], volumes: [1, 0.3, 0.3] },
  'QQS': { intervals: [2, 2, 3], volumes: [0.3, 0.3, 1] },
  'SSQ': { intervals: [3, 3, 2], volumes: [1, 0.3, 0.3] },
  'QQQS': { intervals: [2, 2, 2, 3], volumes: [0.3, 0.3, 0.3, 1] },
  'SSQQ': { intervals: [3, 3, 2, 2], volumes: [1, 1, 0.3, 0.3] },
  'SQSQ': { intervals: [3, 2, 3, 2], volumes: [1, 0.3, 1, 0.3] },
  'QSQS': { intervals: [2, 3, 2, 3], volumes: [0.3, 1, 0.3, 1] },
  'QQSQQ': { intervals: [2, 2, 3, 2, 2], volumes: [0.3, 0.3, 1, 0.3, 0.3] },
};

export default defineComponent({
  name: 'SlapTrack',
  components: {
    Header,
    SlidingImage,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonFab,
    IonFabButton,
    IonIcon
  },
  data() {
    return {
      enableSound: true,
      imagePaths: [] as ImageSetting[],
      slidingImages: [] as Ref<any>[],
      playing: false,
      starting: false,
      bpm: 60,
      minBpm: 10,
      maxBpm: 240,
      timeSignature: '4/4',
      increments: [4, 4, 4, 4],
      intervals: ([] as number[]),
      icons: {
        play: play,
        stop: stop,
        speedometerOutline: speedometerOutline
      }
    }
  },
  async mounted() {
    this.initTracks();
    this.imagePaths = await getEnabledImagePathsAsync();
    await preloadSoundAsync('metronome', '/assets/sounds/metronome-klack.wav');
  },
  watch: {
    async '$route'(to, from) {
      if (to.fullPath == '/tabs/slaptrack') {
        this.imagePaths = await getEnabledImagePathsAsync();
      }

      if (from.fullPath == '/tabs/slaptrack') {
        this.stop();
      }
    }
  },
  computed: {
    settingData(): TrackControlSettings {
      return {
        bpm: this.bpm,
        timeSignature: this.timeSignature,
        enableSound: this.enableSound,
        playing: this.playing
      };
    },
    secondsPerBeat(): number {
      return 60 / this.bpm;
    },
    signature(): SignatureInterface {
      return signatures[this.timeSignature];
    },
    numTracks(): number {
      return this.signature.intervals.length;
    },
    measureTime(): number {
      return INTERVAL_MS * this.signature.intervals.reduce((a, b) => a + b, 0);
    },
    activeTracks(): Ref<any>[] {
      return this.slidingImages.filter((val: Ref<any>, idx: number) => {
        return idx < this.numTracks;
      });
    }
  },
  methods: {
    async openModal() {
      const modal = await modalController
        .create({
          component: TrackControls,
          componentProps: {
            data: this.settingData
          },
        })
      modal.onDidDismiss().then((data: any) => this.updateSettings(data.data));
      return modal.present();
    },
    updateSettings(data: TrackControlSettings) {
      this.bpm = data.bpm;
      this.timeSignature = data.timeSignature;
      this.enableSound = data.enableSound;
      this.playing = data.playing;
    },
    setTimeSignature(s: string) { this.timeSignature = s },
    setEnableSound(e: boolean) { this.enableSound = e },
    getTracks() {
      const slidingImages: Ref<any>[] = [];
      for (let i = 0; i < MAX_TRACKS; i++) {
        slidingImages.push(ref());
      }
      return slidingImages;
    },
    initTracks() {
      this.slidingImages = this.getTracks();
    },
    getRandomImage(): string {
      const weightSum = this.imagePaths.reduce((sum, cv) => sum + cv.percentage, 0) + 1;
      function getRandom() {
        let random = Math.floor(Math.random() * weightSum);

        return function(img: ImageSetting) {
          random -= img.percentage;
          return random <= 0;
        };
      }

      return (this.imagePaths.find(getRandom()) || this.imagePaths[0]).path;
    },
    startLoop(i: number) {
      const track = this.slidingImages[i];
      const volume = this.signature.volumes[i];
      const initSlide = () => {
        track.value.setCurrentImage();
        track.value.play(this.secondsPerBeat, this.enableSound, volume);
        setTimeout(() => {
          if (this.playing) {
            track.value.setNextImage(this.getRandomImage());
          }
        }, this.secondsPerBeat * INTERVAL_MS * 3);
      };
      if (this.playing) {
        track.value.initialize(this.secondsPerBeat, this.enableSound, volume, this.getRandomImage());
        this.intervals[i] = setInterval(initSlide, this.secondsPerBeat * this.measureTime);
      }
    },
    play() {
      this.playing = true;
      this.starting = true;
      let timeout = 0;
      for (let i = 0; i < this.numTracks; i++) {
        setTimeout(() => this.startLoop(i), timeout);
        const delay = INTERVAL_MS * this.signature.intervals[i];
        timeout += this.secondsPerBeat * delay;
      }
      setTimeout(() => {
        this.starting = false;
      }, this.secondsPerBeat * this.measureTime);
    },
    stop() {
      this.playing = false;
      this.starting = false;
      for (let i = 0; i < this.numTracks; i++) {
        this.slidingImages[i].value.clearImages();
        clearInterval(this.intervals[i]);
      }
    },
    validateBpm() {
      if (isNaN(this.bpm) || this.bpm < this.minBpm) {
        this.bpm = this.minBpm;
      }
      else if (this.bpm > this.maxBpm) {
        this.bpm = this.maxBpm;
      }
    }
  }
});
