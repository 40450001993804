<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Controls</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <Suspense>
      <template #default>
        <ion-list>
          <DarkToggle />
          <ion-item>
            <ion-label position="stacked">Beats Per Minute</ion-label>
            <ion-input
              type="number"
              :min="minBpm"
              :max="maxBpm"
              v-model="curData.bpm"
              :disabled="curData.playing"
              @change="validateBpm()"
            ></ion-input>
          </ion-item>
          <ion-range
            :min="minBpm"
            :max="maxBpm"
            color="secondary"
            v-model="curData.bpm"
            :disabled="curData.playing"
          >
            <ion-label slot="start">{{ minBpm }}</ion-label>
            <ion-label slot="end">{{ maxBpm }}</ion-label>
          </ion-range>
          <ion-item>
            <ion-label position="stacked">Time Signature</ion-label>
            <ion-select
              v-model="curData.timeSignature"
              @change="dataChanged()"
              :disabled="curData.playing"
              interface="popover"
            >
              <ion-select-option value="2/4">2/4</ion-select-option>
              <ion-select-option value="3/4">3/4</ion-select-option>
              <ion-select-option value="4/4">4/4</ion-select-option>
              <ion-select-option value="5/4">5/4</ion-select-option>
              <ion-select-option value="6/8">6/8</ion-select-option>
              <ion-select-option value="SQQ">7/16 (SQQ)</ion-select-option>
              <ion-select-option value="QQS">7/16 (QQS)</ion-select-option>
              <ion-select-option value="SSQ">8/16 (SSQ)</ion-select-option>
              <ion-select-option value="QQQS">9/16 (QQQS)</ion-select-option>
              <ion-select-option value="SSQQ">10/16 (SSQQ)</ion-select-option>
              <ion-select-option value="SQSQ">10/16 (SQSQ)</ion-select-option>
              <ion-select-option value="QSQS">10/16 (QSQS)</ion-select-option>
              <ion-select-option value="QQSQQ">11/16 (QQSQQ)</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Sounds</ion-label>
            <ion-toggle
              v-model="curData.enableSound"
              @change="dataChanged()"
              color="success"
            ></ion-toggle>
          </ion-item>
          <ion-button @click="close()">Save</ion-button>
        </ion-list>
      </template>
      <template #fallback>
        <div>Loading...</div>
      </template>
    </Suspense>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import DarkToggle from "@/components/DarkToggle.vue";
import { TrackControlSettings } from "@/models/TrackControlSettings";

export default defineComponent({
  name: "TrackControls",
  components: {
    DarkToggle,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonRange,
    IonSelect,
    IonSelectOption,
    IonToggle,
    IonButton,
  },
  props: {
    data: TrackControlSettings,
  },
  data() {
    return {
      minBpm: 10,
      maxBpm: 240,
      curData: this.data,
    };
  },
  emits: ["dataChanged"],
  methods: {
    validateBpm() {
      if (isNaN(this.curData.bpm) || this.curData.bpm < this.minBpm) {
        this.curData.bpm = this.minBpm;
      } else if (this.bpm > this.maxBpm) {
        this.curData.bpm = this.maxBpm;
      }
    },
    close() {
      modalController.dismiss(this.curData);
    },
  },
});
</script>
