
import { IonLabel, IonItem, IonToggle } from '@ionic/vue';
import { getDarkModeAsync, updateDarkModeAsync } from '@/composables/darkMode';

export default {
  name: 'Tabs',
  components: { IonLabel, IonItem, IonToggle },
  async setup() {
    const dark = await getDarkModeAsync();
    const localupdateDarkModeAsync = async () => {
      await updateDarkModeAsync(dark);
      await getDarkModeAsync();
    }
    return {
      dark,
      localupdateDarkModeAsync
    }
  }
}
