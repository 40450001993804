import { Plugins } from '@capacitor/core';
import { NativeAudioOriginal } from '@ionic-native/native-audio';
import { isPlatform } from '@ionic/vue';

interface Sound {
    key: string;
    asset: string;
    isNative: boolean;
    soundObj: any;
}

const AudioContext = window.AudioContext ||
    (window as any).webkitAudioContext ||
    (window as any).mozAudioContext ||
    (window as any).oAudioContext ||
    (window as any).msAudioContext;
const audioContext = new AudioContext();
const sounds: Sound[] = [];

function fixIOSAudioContext() {
	const fixAudioContext = () => {
		if (audioContext) {
			// Create empty buffer
			const buffer = audioContext.createBuffer(1, 1, 22050);
			const source = audioContext.createBufferSource();
			source.buffer = buffer;
			// Connect to output (speakers)
			source.connect(audioContext.destination);
			// Play sound
			if (source.start) {
				source.start(0);
			}
		}
		// Remove events
		document.removeEventListener('click', fixAudioContext);
		document.removeEventListener('touchstart', fixAudioContext);
		document.removeEventListener('touchend', fixAudioContext);
    };
    document.addEventListener('click', fixAudioContext);
	// iOS 6-8
	document.addEventListener('touchstart', fixAudioContext);
	// iOS 9
	document.addEventListener('touchend', fixAudioContext);
}

export async function preloadSoundAsync(key: string, asset: string) {
    if (isPlatform('cordova') || isPlatform('capacitor')) {
        console.log(`${key} sound loaded natively`);
        const { NativeAudio } = Plugins;
        const soundObj = NativeAudio as NativeAudioOriginal;
        await soundObj.preloadComplex(key, asset, 1, 1, 0);
        sounds.push({
            key: key,
            asset: asset,
            isNative: true,
            soundObj
        });
    }
    else {
        fixIOSAudioContext();
        console.log(`${key} sound loaded for web`);
        
        const context = new AudioContext();
        const request = new XMLHttpRequest();
        request.open('GET', asset, true);
        request.responseType = 'arraybuffer';
        
        // Decode asynchronously
        request.onload = function() {
          context.decodeAudioData(request.response, buffer => {
            sounds.push({
                key: key,
                asset: asset,
                isNative: false,
                soundObj: buffer
            });
          }, err => console.log(`Error loading audio: ${err}`));
        }
        request.send();
    }
}

export async function playSoundAsync(key: string, volume: number, delayInMilliseconds?: number) {
    const soundToPlay = sounds.find(s => s.key == key);
    if (soundToPlay) {
        if (soundToPlay.isNative) {
            setTimeout(async () => {
                const player = soundToPlay.soundObj as NativeAudioOriginal;
                await player.stop(key);
                await player.setVolumeForComplexAsset(key, volume);
                await player.play(key);
            }, delayInMilliseconds);
        }
        else {
            // Create sound buffer
            const buffer = soundToPlay.soundObj as AudioBuffer;
            const source = audioContext.createBufferSource();
            source.buffer = buffer;
            const gain = audioContext.createGain();
            source.connect(gain).connect(audioContext.destination);
            const when = audioContext.currentTime + ((delayInMilliseconds ?? 0) / 1000);
            gain.gain.setValueAtTime(volume, when);
            source.start(when);
        }
    }
}
