
import { createAnimation, IonContent } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { playSoundAsync } from '@/composables/audioControls';

export default defineComponent({
  components: { IonContent },
  props: {
    beatNum: Number
  },
  data: () => ({
    imgSrc: '/assets/images/clap.png',
    imgNextSrc: '',
    playing: false
  }),
  methods: {
    initialize(secondsPerBeat: number, enableSound: boolean, volume: number, img: string) {
      this.playing = true;
      setTimeout(() => {
        if (this.playing) {
          this.imgSrc = img;
          this.imgNextSrc = img;
          if (enableSound) {
            playSoundAsync('metronome', volume);
          }
        }
      }, secondsPerBeat * this.SPEED * 0.55);
    },
    clearImages() {
      this.playing = false;
      this.imgSrc = '';
      this.imgNextSrc = '';
    },
    setCurrentImage() {
      this.imgSrc = this.imgNextSrc;
    },
    setNextImage(img: string) {
      this.imgNextSrc = img;
    }
  },
  setup() {
    const SPEED = 3000;
    const imageRef = ref();
    const imageNextRef = ref();

    const getAnimation = (secondsPerBeat: number) => createAnimation()
      .addElement(imageRef.value)
      .duration(secondsPerBeat * SPEED)
      .keyframes([
        { offset: 0, transform: 'translateY(calc(100vh - 310px))', visibility: 'visible' },
        { offset: 0.5, transform: 'translateY(0vh)' },
        { offset: 0.55, transform: 'translateY(0vh) scale(1.2)' },
        { offset: 0.6, transform: 'translateY(0vh) scale(1)' },
        { offset: 0.85, opacity: 1, transform: 'translateY(0vh) scale(1)' },
        { offset: 1, opacity: 0 }
      ]);

    let soundsEnabled = false;
    const play = (secondsPerBeat: number, enableSound: boolean, volume: number) => {
      soundsEnabled = enableSound;
      setTimeout(() => {
        if (soundsEnabled) {
          playSoundAsync('metronome', volume);
        }
      }, secondsPerBeat * SPEED * 0.55);
      const animation = getAnimation(secondsPerBeat);
      animation.play();
    }

    return { SPEED, imageRef, imageNextRef, play }
  }
});
