<template>
  <Suspense>
    <template #default>
    <ion-item>
      <ion-label position="stacked">Dark Mode:</ion-label>
      <ion-toggle v-model="dark.value" v-on:ionChange="localupdateDarkModeAsync()"></ion-toggle>
    </ion-item>
    </template>
    <template #fallback>
      <div>Loading...</div>
    </template>
  </Suspense>
</template>

<script lang="ts">
import { IonLabel, IonItem, IonToggle } from '@ionic/vue';
import { getDarkModeAsync, updateDarkModeAsync } from '@/composables/darkMode';

export default {
  name: 'Tabs',
  components: { IonLabel, IonItem, IonToggle },
  async setup() {
    const dark = await getDarkModeAsync();
    const localupdateDarkModeAsync = async () => {
      await updateDarkModeAsync(dark);
      await getDarkModeAsync();
    }
    return {
      dark,
      localupdateDarkModeAsync
    }
  }
}
</script>